<template>
  <div class="ui grid container-4">
    <div class="sixteen wide column">
      <div class="instadetails">
        <div class="headerdetails">
          <h5>QUICK STEPS TO</h5>
          <h1>FUND TRANSFER <span>VIA INSTAPAY</span></h1>
          <p>Use the QR PH CODE to send money to Banks and Other E-Wallets</p>
        </div>

        <div class="stepsdetails">
          <div class="step">
            <div class="item">Step 1</div>
            <div class="item">2</div>
            <div class="item">3</div>
          </div>
          <div class="details">
            <div class="item">Open your TayoCash app and tap BANK TRANSFER icon</div>
            <div class="item">Enter amount and fill in details — or for hassle-free transfers, simply scan or upload Recipient’s QR PH Code Code</div>
            <div class="item">Review and confirm transaction details</div>
          </div>
          <div class="buttons">
            <a class="yellow" href="https://assets.prod.tayocash.com/forms/list_of_instapay_participating_banks.pdf" download target="_blank">DOWNLOAD LIST OF BANKS AND EMIs</a>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/wn4.png">
  </div>
</template>

<script>
export default {
  name: 'content4'
}
</script>

<style scoped lang='scss'></style>
